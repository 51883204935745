import React from "react"
import Helmet from "react-helmet"
import Navigation from "../components/Navigation"
import { Link } from "gatsby"

import * as LawPagesModule from "../styles/law-pages.module.css"

const DatenschutzPage = () => (
  <>
    <Helmet>
      <title>Datenschutzerklärung - Steimers Strandbar</title>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <meta name="description"
            content="Die Strandbar auf der Nordseeinsel Juist"/>
    </Helmet>
    <Navigation/>
    <div className={LawPagesModule.container}>
      <div className={LawPagesModule.textContainer}>
        <h2>Datenschutzerklärung</h2>

        <h3>Grundlegendes</h3>
        <p>
          Diese Datenschutzerklärung soll die Nutzer dieser Webseite über die Art, den Umfang und<br/>
          den Zweck der Erhebung und Verwendung personenbezogener Daten durch den<br/>
          Webseitenbetreiber <Link to="/impressum">Steimers Strandbar</Link> informieren.<br/><br/>
        </p>

        <p>
          Bei den personenbezogenen Daten handelt es sich auf dieser Webseite, um die Zugriffsdaten,<br/>
          welche ihr Browser automatisch beim Aufrufen dieser Seite, mitsendet.<br/><br/>
        </p>

        <h3>Zugriffsdaten</h3>
        <p>
          Wir protokollieren alle Zugriffe auf dieser Webseite und speichern diese in Server-Logdateien auf dem
          Server.<br/>
          Die Zugriffsdaten umfassen:<br/>
        </p>
        <ul>
          <li>Die angeforderte Webseite</li>
          <li>Uhrzeit zum Zeitpunkt des Zugriffes</li>
          <li>Menge der gesendeten Daten in Bytes</li>
          <li>Verwendeter Browser</li>
          <li>Verwendetes Betriebssystem</li>
          <li>Verwendete IP-Adresse</li>
        </ul>
        <p>
          Die Server-Logdateien werden für unbestimmte Zeit gespeichert und dienen dem Zweck der Sicherheit, um
          z.B.<br/>
          Missbrauchsfälle aufklären zu können, und der Fehlerbehebung, damit wir die Ursache von auftretenden<br/>
          Fehlern nachvollziehen können<br/>
        </p>

        <h3>Cookies</h3>
        <p>
          Diese Webseite speichert keine Cookies in ihrem Browser.<br/>
          <br/>
        </p>

        <h3>Drittanbieter</h3>
        <p>
          Für die Darstellung der Karteninformationen verwendet diese Webseite <Link
          to={"https://www.openstreetmap.org/about"}>OpenStreetMap</Link><br/>
        </p>
        <Link to={"/"} className='mt-1'>Zurück zur Hauptseite</Link>
      </div>
    </div>
  </>
)

export default DatenschutzPage;
